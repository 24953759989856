

















































































import { computed, defineComponent, onMounted, reactive } from "@vue/composition-api";
import { getAllAchievements } from "@/api/achievementService";
import { getMyTeams } from "@/api/teamService";
import { userModule } from "@/store/modules/user";
import fx from "fireworks";
import Learnlink from "@learnlink/interfaces";
import Moment from "@/services/moment";
import ProfilePhoto from "@/components/profile/ProfilePhoto.vue";
import TeamCardStudent from "@/components/student/TeamCardStudent.vue";
import UnreadAchievementsDialog from "@/components/achievements/UnreadAchievementsDialog.vue";
import Uploader from "@/components/settings/upload/Uploader.vue";
import useAsyncData from "@/hooks/useAsyncData";
import SupportPhoneAlert from "@/components/SupportPhoneAlert.vue";
import StudentAchievementsStudent from "@/components/student/StudentAchievementsStudent.vue";

export default defineComponent({
  name: "StudentDashboard",
  components: { ProfilePhoto, StudentAchievementsStudent, SupportPhoneAlert, TeamCardStudent, UnreadAchievementsDialog, Uploader },
  setup() {
    const { isLoading, data: teams } = useAsyncData(() => getMyTeams(userModule.state.userId));

    async function updateProfile(newPhotoURL: string) {
      await userModule.actions.updateUserProfile({ photoURL: newPhotoURL });
    }
    const filteredTeams = computed(() => teams.value?.filter(team => !team.previousTeamID || team.teamSessions[0].startTime < Moment().add(1, "week").unix()));

    const vm = reactive({
      unreadAchievements: {} as Record<string, Learnlink.Achievement.ForPresentation>,
      unreadAchievementsDialogVisible: false,
      allAchievements: {} as Record<string, Learnlink.Achievement.ForPresentation>,
      allAchievementsLength: 0,
      loading: false,
      myAchievements: {} as Record<string, Learnlink.Achievement.UserAchievement>,
      myAchievementsLength: 0,
    });

    onMounted(async () => {
      await mount();
      if (vm.unreadAchievementsDialogVisible) {
        fireworksLoop();
        setTimeout(fireworksLoop, 200);
      }
    });

    async function mount() {
      vm.loading = true;

      const userAchievements = userModule.state.user.achievements || [];
      const achievements = await getAllAchievements();

      if (userAchievements.length) {
        for (const myAchievement of userAchievements) {
          vm.myAchievements[myAchievement.ID] = myAchievement;
        }
      }
      for (const achievement of achievements) {
        const achievementForPresentation = achievement as Learnlink.Achievement.ForPresentation;
        achievementForPresentation.achieved = vm.myAchievements[achievement.ID]?.achieved || 0;
        achievementForPresentation.read = vm.myAchievements[achievement.ID]?.read || 0;
        vm.allAchievements[achievement.ID] = achievementForPresentation;
      }
      vm.unreadAchievements = Object.fromEntries(
        Object.entries(vm.allAchievements)
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          .filter(([key, value]) => value.achieved && !value.read)
      );
      vm.myAchievementsLength = Object.keys(vm.myAchievements).length;
      vm.allAchievementsLength = Object.keys(vm.allAchievements).length;
      const unreadAchievementLength = Object.keys(vm.unreadAchievements).length;
      if (unreadAchievementLength) {
        vm.unreadAchievementsDialogVisible = true;
      }
      vm.loading = false;
    }

    async function acceptAchievements() {
      const updatedUserAchievements = userModule.state.user.achievements.map((achievement) => {
        const updatedAchievement = { ...achievement };
        if (!updatedAchievement.read) updatedAchievement.read = Moment().unix();
        return updatedAchievement;
      });
      await userModule.actions.updateUserObject({ achievements: updatedUserAchievements });
      vm.unreadAchievementsDialogVisible = false;
    }

    function fireworksLoop() {
      fx({
        x: Math.random() * window.innerHeight,
        y: Math.random() * window.innerWidth,
        canvasWidth: 1000,
        count: 60,
        colors: ["#29cdff", "#78ff44", "#ff718d"]
      });
      if (vm.unreadAchievementsDialogVisible) setTimeout(fireworksLoop, 900);
    }

    return {
      acceptAchievements,
      isLoading,
      profile: computed(() => userModule.state.profile),
      filteredTeams,
      updateProfile,
      vm,
    };
  }
});
