
































































import AchievementCard from "@/components/achievements/AchievementCard.vue";
import { computed, defineComponent, onMounted, PropType, reactive } from "@vue/composition-api";
import Learnlink from "@learnlink/interfaces";

export default defineComponent({
  name: "UnreadAchievementsDialog",
  components: { AchievementCard },
  props: {
    unreadAchievements: {
      type: Object as PropType<Record<string, Learnlink.Achievement.ForPresentation>>,
      required: true,
    },
    value: {
      type: Boolean as PropType<boolean>,
      required: true,
    }
  },
  setup(props, { emit, refs }) {
    const vm = reactive({
      soundOff: true,
    });
    let audio: HTMLAudioElement;
    onMounted(() => {
      audio = refs.audio as HTMLAudioElement;
    });

    const unreadAchievementLength = computed(() => Object.keys(props.unreadAchievements).length);
    async function playSound() {
      vm.soundOff = false;
      audio.volume = 0.4;
      await audio.play();
    }
    function pauseSound() {
      vm.soundOff = true;
      let actualVolumeFadeOut = audio.volume;
      setInterval(function() {
        actualVolumeFadeOut = actualVolumeFadeOut - 0.1;
        if (actualVolumeFadeOut >= 0) {
          audio.volume = actualVolumeFadeOut;
        }
        else {
          audio.pause();
          clearInterval();
        }
      }, 200);
    }
    async function accept() {
      await audio.pause();
      emit("accepted");
    }
    return {
      accept,
      emit,
      pauseSound,
      playSound,
      unreadAchievementLength,
      vm,
    };
  },
});
