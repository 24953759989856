






















import { defineComponent, PropType } from "@vue/composition-api";

export default defineComponent({
  name: "AchievementPhotoTeamMembers",
  props: {
    url: {
      type: String as PropType<string>,
      required: true,
    },
    height: {
      type: Number as PropType<number>,
      default: 120,
    },
    width: {
      type: Number as PropType<number>,
      default: 120,
    },
    name: {
      type: String as PropType<string>,
      required: true,
    }
  },
});
