





































































import { defineComponent, PropType } from "@vue/composition-api";
import { getAllAchievements } from "@/api/achievementService";
import { userModule } from "@/store/modules/user";
import AchievementPhotoTeamMembers from "@/components/achievements/AchievementPhotoTeamMembers.vue";
import Learnlink from "@learnlink/interfaces";
import ProfilePhoto from "../profile/ProfilePhoto.vue";
import useAsyncData from "@/hooks/useAsyncData";

export default defineComponent({
  components: { AchievementPhotoTeamMembers, ProfilePhoto },
  name: "TeamMembersCustomer",
  props: {
    team: {
      type: Object as PropType<Learnlink.Team.FullMetadata>,
      required: true
    }
  },
  setup() {
    const { data: achievements } = useAsyncData(() => getAllAchievements());

    function getAchievementPhoto(ID: string): string {
      const achievement = achievements.value?.filter(achievement => achievement.ID === ID)[0];
      return achievement?.achievedImageURL || "";
    }

    function getAchievementName(ID: string): string {
      const achievement = achievements.value?.filter(achievement => achievement.ID === ID)[0];
      return achievement?.title || "";
    }

    return {
      achievements,
      betaTester: userModule.state.user.betaTester,
      getAchievementPhoto,
      getAchievementName,
      thisUser: userModule.state.userId,
    };
  },
});
