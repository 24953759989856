








































































import { computed, defineComponent, PropType } from "@vue/composition-api";
import { createLinks } from "@/helpers/stringUtils";
import { getReadableTimeForTeam } from "@/helpers/timeUtils";
import { getTeamSessions } from "@/api/teamSessionService";
import { userModule } from "@/store/modules/user";
import Credentials from "@/components/Credentials.vue";
import Learnlink from "@learnlink/interfaces";
import TeamCardHeader from "@/components/shared/TeamCardHeader.vue";
import TeamInformation from "@/components/shared/TeamInformation.vue";
import TeamMembersCustomer from "@/components/customer/TeamMembersCustomer.vue";
import useAsyncData from "@/hooks/useAsyncData";

export default defineComponent({
  name: "TeamCardStudent",
  components: {
    Credentials,
    TeamInformation,
    TeamMembersCustomer,
    TeamCardHeader,
  },
  props: {
    team: {
      type: Object as PropType<Learnlink.Team.FullMetadata>,
      required: true,
    },
  },
  setup(props) {
    const { data: dates } = useAsyncData(() => getTeamSessions(props.team.ID));

    const account = computed(() => userModule.state.account);

    const readableTime = computed(() => getReadableTimeForTeam(dates.value || [], props.team.time));

    const sellerName = computed(() => props.team.verboseSellers[0]?.firstName);

    const newTeamMessages = computed((): number => {
      const lastRead = userModule.state.user.lastReadTeamMessages?.filter(o => o.teamID === props.team.ID)[0];
      if (!lastRead) return props.team.information?.length || 0;
      return props.team.information?.filter(message => message.created > lastRead.read || 0).length;
    });

    return {
      account,
      createLinks,
      minecraftCourse: computed(() => props.team.course.title.toLowerCase().includes("minecraft")),
      newTeamMessages,
      readableTime,
      sellerName,
    };
  }
});
