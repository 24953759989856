









































import { defineComponent, onMounted, reactive } from "@vue/composition-api";
import { getAllAchievements } from "@/api/achievementService";
import { userModule } from "@/store/modules/user";
import AchievementCard from "@/components/achievements/AchievementCard.vue";
import Learnlink from "@learnlink/interfaces";

export default defineComponent({
  name: "StudentAchievementsStudent",
  components: { AchievementCard },

  setup() {
    const vm = reactive({
      allAchievements: {} as Record<string, Learnlink.Achievement.ForPresentation>,
      allAchievementsLength: 0,
      loading: false,
      myAchievements: {} as Record<string, Learnlink.Achievement.UserAchievement>,
      myAchievementsLength: 0,
    });
    onMounted(async() => {
      await mount();
    });

    async function mount() {
      vm.loading = true;
      const userAchievements = userModule.state.user.achievements || [];
      const achievements = await getAllAchievements();

      if (userAchievements.length) {
        for (const myAchievement of userAchievements) {
          vm.myAchievements[myAchievement.ID] = myAchievement;
        }
      }
      for (const achievement of achievements) {
        const achievementForPresentation = achievement as Learnlink.Achievement.ForPresentation;
        achievementForPresentation.achieved = vm.myAchievements[achievement.ID]?.achieved || 0;
        achievementForPresentation.read = vm.myAchievements[achievement.ID]?.read || 0;
        vm.allAchievements[achievement.ID] = achievementForPresentation;
      }
      vm.myAchievementsLength = Object.keys(vm.myAchievements).length;
      vm.allAchievementsLength = Object.keys(vm.allAchievements).length;
      vm.loading = false;
    }
    return {
      vm,
    };
  },
});
